import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, merge, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { M365ConnectionStatus } from 'src/app/settings/account/models/connection-status';
import { PartnerAuthentication } from 'src/app/settings/account/models/partner-authentication';
import { ErrorModalService } from 'src/app/settings/shared/services/error-modal.service';
import { RelativeUrlsProvider } from 'src/app/settings/shared/services/relative.urls.provider';
import {
  AuthenticationType,
  PartnerPortalUserClaims,
} from '../../../models/partner-portal-user-claims';
import { ClaimsService } from '../../../services/claims.service';
import { AddM365MemberRequest } from '../models/add-m365-member-request';
import { EditMemberRequest } from '../models/edit-member-request';
import { InviteMemberRequest } from '../models/invite-member-request';
import { M365UserSearchFilter } from '../models/m365-user-search-filter';
import { Member } from '../models/member';
import PermissionCheckbox from '../models/permission.checkbox';
import RoleRadioButton from '../models/role.radio.button';
import { AuthenticationSettingsService } from '../services/authentication.settings.service';
import { MemberActionEffectService } from '../services/member-action-effect.service';
import { MemberEmailValidator } from '../services/member-email-validator';
import { MembersRoleProvider } from '../services/members.role.provider';
import { MembersService } from '../services/members.service';

@Component({
  selector: 'sk-add-edit-connectwise-backup-member-form',
  templateUrl: './add-edit-connectwise-member-backup-form.component.html',
  styleUrl: './add-edit-connectwise-member-backup-form.component.scss',
})
export class AddEditConnectWiseBackupMemberFormComponent {
  partnerAuthentication: PartnerAuthentication;
  m365AuthConnectionStatus: M365ConnectionStatus;
  groupsSyncEnabled: boolean;
  editState: boolean;
  member: Member;
  partnerClaims: PartnerPortalUserClaims;
  updating = false;
  initialLoading: boolean;
  addMemberForm: UntypedFormGroup;
  addMemberEmailIsValid = true;
  addMemberEmailIsUnique = true;
  isSubmitButtonClicked = false;
  isRoleSelected = true;
  executingMainAction = false;
  isResetPasswordUnavailable = true;
  cloudBackupCheckbox: PermissionCheckbox;
  rolesRadioButtons: RoleRadioButton[];
  cloudBackupScope = 'Cloud_Backup';
  click$ = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();

  loadingEmail = false;
  emailQueryIsEmpty = true;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private membersService: MembersService,
    private abstractUserProvider: AbstractUserProvider,
    private partnerService: ClaimsService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private errorModalService: ErrorModalService,
    private membersActionEffectHandler: MemberActionEffectService,
    private authSettingsService: AuthenticationSettingsService,
    private memberEmailValidator: MemberEmailValidator
  ) {
    this.editState = this.isEditState();
    this.initialLoading = true;

    // Populating roles radio buttons with descriptions
    forkJoin(
      MembersRoleProvider.ConnectWiseBackupRoles.map((role) => {
        return this.translateService
          .get(`settings.members.roles.${role.key.toLowerCase()}-desc`)
          .pipe(
            map((descriptionList) => {
              const descriptions: string[] = [];
              for (const field of Object.keys(descriptionList)) {
                descriptions.push(descriptionList[field]);
              }
              const rolesRadioButtons: RoleRadioButton = {
                roleKey: role.key,
                roleName: `${role.displayNameLocKey}-role`,
                descriptionList: descriptions,
              };
              return rolesRadioButtons;
            })
          );
      })
    ).subscribe((res) => (this.rolesRadioButtons = res));

    this.translateService
      .get('settings.members.licenses.cloud-backup.cloud-backup-desc')
      .pipe(
        map((descriptionList) => {
          const descriptions: string[] = [];
          for (const field of Object.keys(descriptionList)) {
            descriptions.push(descriptionList[field]);
          }
          const cloudBackupCheckbox: PermissionCheckbox = {
            permissionName: 'settings.members.access.cloud-backup',
            descriptionList: descriptions,
            disabled: false,
          };
          return cloudBackupCheckbox;
        })
      )
      .subscribe((checkbox) => (this.cloudBackupCheckbox = checkbox));
  }

  ngOnInit(): void {
    if (this.editState) {
      this.initEditState();
    } else {
      this.initAddState();
    }

    this.fetchSettings();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initEditState(): void {
    forkJoin([
      this.activeRoute.paramMap.pipe(
        take(1),
        map((params) => params.get('memberId')),
        switchMap((memberId) => this.membersService.getMember(memberId)),
        takeUntil(this.destroy$)
      ),
      this.partnerService
        .getPartnerPortalUserClaimsAsync(
          this.abstractUserProvider.getCurrentUser().email
        )
        .pipe(takeUntil(this.destroy$)),
    ])
      .pipe(
        tap((memberWithClaims) => {
          const [member, partnerClaims] = memberWithClaims;
          this.partnerClaims = partnerClaims;
          this.member = member;

          this.isResetPasswordUnavailable =
            partnerClaims === undefined ||
            partnerClaims.authenticationType === AuthenticationType.M365Auth;

          this.initialLoading = false;
        }),
        catchError((error) => {
          return this.handleErrorDuringInitialLoading(error);
        })
      )
      .subscribe();
  }

  initAddState(): void {
    forkJoin([
      this.membersService
        .getMember(this.abstractUserProvider.getCurrentUser().userId)
        .pipe(takeUntil(this.destroy$)),
      this.partnerService
        .getPartnerPortalUserClaimsAsync(
          this.abstractUserProvider.getCurrentUser().email
        )
        .pipe(takeUntil(this.destroy$)),
    ])
      .pipe(
        tap((memberWithClaims) => {
          const [member, partnerClaims] = memberWithClaims;
          this.partnerClaims = partnerClaims;
          this.initialLoading = false;

          member.unlicensedPermissions.forEach((permission) => {
            permission.Enabled = true;
          });

          this.member = {
            ...this.getEmptyMember(),
            unlicensedPermissions: member.unlicensedPermissions,
          };
          this.addMemberEmailIsValid = true;
          this.addMemberEmailIsUnique = true;

          this.addMemberForm = this.formBuilder.group(
            {
              email: new UntypedFormControl(
                '',
                [Validators.email, Validators.required],
                [this.memberEmailValidator.createValidator(this.membersService)]
              ),
            },
            { updateOn: 'blur' }
          );

          this.addMemberForm.statusChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.validateEmail());
        }),
        catchError((error) => {
          return this.handleErrorDuringInitialLoading(error);
        })
      )
      .subscribe();
  }

  changeRole(role: string): void {
    if (!this.isOwnMember()) {
      this.member.role = role;
      this.validateRole();
    }
  }

  validateForm(): void {
    if (!this.addMemberForm.pending) {
      this.validateEmail();
    }
    this.validateRole();
  }

  validateRole(): void {
    this.isRoleSelected = this.member.role !== '';
  }

  validateEmail(): void {
    this.addMemberEmailIsUnique =
      !this.addMemberForm.controls.email.errors?.memberEmailExists;
    this.addMemberEmailIsValid = this.addMemberEmailIsUnique
      ? !this.addMemberForm.invalid
      : true;
  }

  isFormValid(): boolean {
    return (
      !this.isSubmitButtonClicked ||
      (this.isRoleSelected &&
        this.addMemberEmailIsValid &&
        this.addMemberEmailIsUnique)
    );
  }

  resetEmailValidation(): void {
    this.addMemberEmailIsValid = true;
    this.addMemberEmailIsUnique = true;
  }

  getAccountSettingsPageUrl(): string {
    return RelativeUrlsProvider.ManageAccountPageUrl;
  }

  getAuthTypeLocKey(): string {
    let authTypeLocKey = 'settings.common.authentication.skykick';
    if (this.isM365Authentication()) {
      authTypeLocKey = 'settings.common.authentication.m365';
    } else if (this.partnerClaims.isMFAEnabled) {
      authTypeLocKey = 'settings.common.authentication.skykick-mfa';
    }
    return authTypeLocKey;
  }

  isM365Authentication(): boolean {
    return (
      this.partnerClaims.authenticationType === AuthenticationType.M365Auth
    );
  }

  resetPassword(member: Member): void {
    this.membersActionEffectHandler
      .resetPassword(this.membersService.resetPassword(member.username), member)
      .subscribe();
  }

  deactivateAccount(member: Member): void {
    this.membersActionEffectHandler
      .deactivate(this.membersService.deactivateMember(member.id), member, () =>
        this.goToParentPage(member.id)
      )
      .subscribe();
  }

  cancel(): void {
    this.goToParentPage();
  }

  addM365Member(): void {
    this.isSubmitButtonClicked = true;
    this.validateForm();

    if (!this.isFormValid() || this.addMemberForm.pending) {
      return;
    } else {
      this.executingMainAction = true;
      const addMemberRequest: AddM365MemberRequest = {
        email: this.addMemberForm.get('email').value,
        isAdmin: this.isAdminMember(),
        isDeveloper: this.member.isDeveloper,
        licenses: [],
        unlicensedPermissions: this.member.unlicensedPermissions,
      };

      this.membersService
        .addM365Member(addMemberRequest)
        .pipe(
          tap((result) => {
            if (result) {
              this.translateService
                .get('settings.members.actions.add-success')
                .pipe(
                  tap((successText: string) =>
                    this.toastrService.success(successText)
                  )
                )
                .subscribe();
              this.goToParentPage(result.UserId);
            } else {
              throw new Error('Unsuccessful response when adding member');
            }
          }),
          catchError((error) => {
            this.translateService
              .get('settings.members.actions.add-failed')
              .pipe(
                tap((errorText: string) => this.toastrService.error(errorText))
              )
              .subscribe();
            return throwError(() => error);
          })
        )
        .subscribe({
          complete: () => (this.executingMainAction = false),
        });
    }
  }

  sendInvite(): void {
    this.isSubmitButtonClicked = true;
    this.validateForm();
    if (!this.isFormValid() || this.addMemberForm.pending) {
      return;
    } else {
      this.executingMainAction = true;
      const inviteMemberRequest: InviteMemberRequest = {
        email: this.addMemberForm.get('email').value,
        isAdmin: this.isAdminMember(),
        isDeveloper: this.member.isDeveloper,
        licenses: [],
        unlicensedPermissions: this.member.unlicensedPermissions,
      };
      this.membersService
        .inviteMember(inviteMemberRequest)
        .pipe(
          tap((result) => {
            if (result) {
              this.translateService
                .get('settings.members.actions.add-success')
                .pipe(
                  tap((successText: string) =>
                    this.toastrService.success(successText)
                  )
                )
                .subscribe();
              this.goToParentPage(result.UserId);
            } else {
              throw new Error('Unsuccessful response when adding member');
            }
          }),
          catchError((error) => {
            return this.handleErrorDuringInitialLoading(error);
          })
        )
        .subscribe({
          complete: () => (this.executingMainAction = false),
        });
    }
  }

  searchEmail = (searchTerm$: Observable<string>) => {
    return merge(this.click$, searchTerm$.pipe(debounceTime(200))).pipe(
      distinctUntilChanged(),
      tap((term) => {
        this.loadingEmail = true;
        term === ''
          ? (this.emailQueryIsEmpty = true)
          : (this.emailQueryIsEmpty = false);
      }),
      switchMap((startsWith) =>
        this.membersService
          .getM365Users(new M365UserSearchFilter({ searchTerm: startsWith }))
          .pipe(
            map((user) => user.map((x) => x.userPrincipalName)),
            catchError((error) => {
              this.translateService
                .get('settings.members.form.search-email-failed')
                .pipe(
                  tap((errorText: string) =>
                    this.toastrService.error(errorText)
                  )
                )
                .subscribe();
              return throwError(() => error);
            }),
            finalize(() => (this.loadingEmail = false))
          )
      ),
      takeUntil(this.destroy$)
    );
  };

  searchFormatter(m365User: string): string {
    return m365User;
  }

  selectItem(event: NgbTypeaheadSelectItemEvent): void {
    this.addMemberEmailIsValid = true;
    this.emailQueryIsEmpty = false;
  }

  updateMember(): void {
    this.updating = true;

    const editMemberRequest: EditMemberRequest = {
      contactId: this.member.id,
      email: this.member.email,
      firstName: this.member.firstName,
      lastName: this.member.lastName,
      isAdmin: this.isAdminMember(),
      isDeveloper: this.member.isDeveloper,
      licenses: [],
      unlicensedPermissions: this.member.unlicensedPermissions,
    };

    this.membersService
      .editMember(editMemberRequest)
      .pipe(
        tap((result) => {
          if (result) {
            this.translateService
              .get('settings.members.actions.edit-success')
              .pipe(
                tap((successText: string) =>
                  this.toastrService.success(successText)
                )
              )
              .subscribe();
          } else {
            throw new Error('Unsuccessful response when editing member');
          }
        }),
        catchError((error) => {
          this.translateService
            .get('settings.members.actions.edit-failed')
            .pipe(
              tap((errorText: string) => this.toastrService.error(errorText))
            )
            .subscribe();
          return throwError(() => error);
        })
      )
      .subscribe({
        complete: () => (this.updating = false),
      });
  }

  private fetchSettings() {
    this.authSettingsService
      .fetchAuthenticationSettings()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([
          partnerAuthentication,
          groupSyncStatus,
          m365AuthConnectionStatus,
        ]) => {
          this.partnerAuthentication = partnerAuthentication;
          this.groupsSyncEnabled = groupSyncStatus.groupsSyncEnabled;
          this.m365AuthConnectionStatus = m365AuthConnectionStatus;
        }
      );
  }

  private handleErrorDuringInitialLoading(error: any): Observable<never> {
    this.initialLoading = false;
    this.errorModalService.openErrorModal();
    return throwError(() => error);
  }

  private getEmptyMember(): Member {
    return {
      id: '',
      username: '',
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      role: '',
      status: '',
      access: [],
      isUsernameMapped: false,
      permissionScopes: [],
      unlicensedPermissions: [],
      isDeveloper: false,
      licenses: [],
    };
  }

  private goToParentPage(id: string = undefined): void {
    if (this.isEditState()) {
      this.router.navigateByUrl('settings/users/members', {
        state: { refreshMemberId: id },
      });
    } else {
      this.router.navigateByUrl('settings/users/members', {
        state: { addMemberId: id },
      });
    }
  }

  private isEditState(): boolean {
    const segments = this.getUrlSegments();
    return segments[segments.length - 1].path === 'edit';
  }

  isOwnMember(): boolean {
    return (
      this.abstractUserProvider.getCurrentUser().email.toLowerCase() ===
      this.member.email.toLowerCase()
    );
  }

  private isAdminMember(): boolean {
    return MembersRoleProvider.isAdmin(this.member.role);
  }

  private getUrlSegments(): UrlSegment[] {
    const tree = this.router.parseUrl(this.router.url);
    return tree.root.children.primary.segments;
  }

  onEmailSearchClear() {
    this.addMemberForm.controls.email.setValue('');
    this.emailQueryIsEmpty = true;
  }

  isSubmitDisabled() {
    return this.executingMainAction || this.addMemberForm.controls.email.pending
      ? true
      : null;
  }
}
