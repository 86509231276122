import { Injectable } from '@angular/core';

import { Member } from '../models/member';
import { MemberResult } from '../models/member-result';
import { MembersAccessProvider } from './members.access.provider';
import { MembersLicensesProvider } from './members.licenses.provider';
import {
  MembersPermissionSummaryProvider,
} from './members.permission.summary.provider';
import { MembersStatusProvider } from './members.status.provider';

@Injectable({
    providedIn: 'root'
})
export class MembersConverter {
    public convertToMember(memberResult: MemberResult): Member{
        const accessRights = memberResult.Access.map(x => MembersAccessProvider.getAccessValueByKey(x));
        const member: Member = {
            id: memberResult.Id,
            username: memberResult.Username,
            firstName: memberResult.FirstName,
            lastName: memberResult.LastName,
            fullName: this.getFullName(memberResult),
            email: memberResult.EmailAddress,
            role: memberResult.PartnerPortalRole,
            status: MembersStatusProvider.getStatusValueByKey(memberResult.Status),
            access: accessRights,
            permissionScopes: MembersPermissionSummaryProvider.getPermissionScopes(memberResult.Permissions),
            unlicensedPermissions: memberResult.UnlicensedPermissions?.filter(up =>
              memberResult.Permissions.some(p => p.Name === up.Name && p.Scope === up.Scope)),
            isUsernameMapped: memberResult.IsUsernameMapped,
            isDeveloper: memberResult.IsDeveloper,
            licenses: MembersLicensesProvider.getLicenses(memberResult.Licenses),
        };

        return member;
    }

    private getFullName(memberResult: MemberResult): string{
      let fullName = `${memberResult.FirstName} ${memberResult.LastName}`;
      if(fullName.trim().length == 0){
        fullName = memberResult.Username;
      }
      return fullName;
    }
}
