import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, fromEvent, iif, merge, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError, debounceTime, distinctUntilChanged, filter, finalize, map, skip, startWith, switchMap, takeUntil, tap
} from 'rxjs/operators';

import { EmptyPanelComponent, OperationCancelledError, SkyKickModalService, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular';

import { AuthService } from 'src/app/auth.service';
import { M365ConnectionStatus } from 'src/app/settings/account/models/connection-status';
import { PartnerAuthentication } from 'src/app/settings/account/models/partner-authentication';
import { DividerFilterItem } from 'src/app/settings/models/divider-filter-item';
import { FilterArea } from 'src/app/settings/models/filter-area';
import { FilterItem } from 'src/app/settings/models/filter-item';
import { TaskType } from 'src/app/settings/models/task-type';
import { ErrorModalService } from 'src/app/settings/shared/services/error-modal.service';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';
import { environment } from 'src/environments/environment';

import { AuthenticationType, PartnerPortalUserClaims } from '../../models/partner-portal-user-claims';
import { ClaimsService } from '../../services/claims.service';
import { UsersComponentContext } from '../users-component-context';
import { MemberDetailsPanelComponent } from './member-details-panel/member-details-panel.component';
import { MemberDetailsPanelContext } from './member-details-panel/models/member-details-panel-context';
import { Member } from './models/member';
import { MemberStatus } from './models/member-status';
import { MembersSearchFilter } from './models/members-search-filter';
import { RemoveMemberModalComponent } from './remove-member-modal/remove-member-modal.component';
import { ResetMfaModalComponent } from './reset-mfa-modal/reset-mfa-modal.component';
import { AuthenticationSettingsService } from './services/authentication.settings.service';
import { MemberActionEffectService } from './services/member-action-effect.service';
import { MemberValidator } from './services/member-validator';
import { MembersAccessProvider } from './services/members.access.provider';
import { MembersErrorProvider } from "./services/members.error.provider";
import { MembersRoleProvider } from './services/members.role.provider';
import { MembersService } from './services/members.service';
import { MembersStatusProvider } from './services/members.status.provider';

@Component({
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit, AfterViewInit, OnDestroy {
  private filterSubject = new Subject<string>();
  private filter$: Observable<string> = this.filterSubject.asObservable();
  private currentPageNumber = 1;
  private destroy$: Subject<void> = new Subject<void>();

  private accountRolesFilterKey = 'roles';
  private accessFilterKey = 'access';
  private statusFilterKey = 'status';
  private errorFilterKey = 'error';
  private addMemberId: string = undefined;
  private refreshMemberId: string = undefined;
  private readonly accessSortingList = [
    'CloudBackup',
    'SecurityManager',
    'CloudManager',
    'Migration',
    'Billing',
  ];
  private user: ISkyKickPartnerPortalUser;

  initialLoading = true;
  loadMembersError = false;
  showLoadMore = false;
  loadingMore = false;
  loading = false;
  showFilterBar = false;
  isLoadingSettings = true;

  partnerAuthentication: PartnerAuthentication;
  m365AuthConnectionStatus: M365ConnectionStatus;
  groupsSyncEnabled: boolean;
  filterAreas: FilterArea[] = [];
  members: Member[];
  isResetPasswordUnavailable = true;
  isSendInviteUnavailable = true;
  isResetMfaAvailable = false;
  partnerClaims: PartnerPortalUserClaims;
  areAllUsersMatched: boolean = true;
  isReauthenticateRequired = false;

  @ViewChild('searchMembersInput')
  input: ElementRef;
  @ViewChild('clearSearchBtn')
  clearSearchBtn: ElementRef;
  @ViewChild('searchMembersBtn')
  searchMembersBtn: ElementRef;

  constructor(
    private authSettingsService: AuthenticationSettingsService,
    private membersService: MembersService,
    private claimsService: ClaimsService,
    private abstractUserProvider: AbstractUserProvider,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private modalService: SkyKickModalService,
    private errorModalService: ErrorModalService,
    private taskManagerService: TaskManagerService,
    private usersComponentContext: UsersComponentContext,
    private membersActionEffectHandler: MemberActionEffectService,
    private errorFactory: ErrorFactory,
    private memberValidator: MemberValidator,
    private authService: AuthService
  ) {
    const statusFilterArea: FilterArea = {
      displayName: 'settings.members.table.status',
      key: this.statusFilterKey,
      selectedFiltersCount: 0,
      filters: [],
    };
    statusFilterArea.filters.push(
      ...MembersStatusProvider.Statuses.filter((x) => x.key !== 1).map(
        (status) => new FilterItem(status.displayNameLocKey, status.value)
      ),
      ...MembersStatusProvider.Statuses.filter((x) => x.key === 1).map(
        (status) =>
          new DividerFilterItem(status.displayNameLocKey, status.value)
      )
    );

    const errorFilterArea: FilterArea = {
      displayName: 'settings.members.table.error',
      key: this.errorFilterKey,
      selectedFiltersCount: 0,
      filters: [],
    };
    errorFilterArea.filters.push(
      ...MembersErrorProvider.Errors.map(
        (error) => new FilterItem(error.displayNameLocKey, error.key)
      )
    );

    this.filterAreas = [
      statusFilterArea,
      errorFilterArea,
    ];
    this.addMemberId =
      this.router.getCurrentNavigation()?.extras?.state?.addMemberId;
    this.refreshMemberId =
      this.router.getCurrentNavigation()?.extras?.state?.refreshMemberId;
  }

  ngOnInit(): void {
    this.user = this.abstractUserProvider.getCurrentUser();

    forkJoin([
      this.getMembers('', 1),
      this.claimsService.getPartnerPortalUserClaimsAsync(this.user.email).pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.handleLoadingError();
          return throwError(() => error);
        })
      ),
      this.addMemberId || this.refreshMemberId
        ? this.membersService
            .getMember(this.addMemberId ?? this.refreshMemberId)
            .pipe(
              catchError((error) => {
                this.handleLoadingError();
                return throwError(() => error);
              })
            )
        : of<Member>(null),
    ]).subscribe({
      next: ([members, claims, memberToRefresh]) => {
        this.members = members;
        if (memberToRefresh) {
          let memberIndex = this.members.findIndex(
            (x) => x.id == memberToRefresh.id
          );
          if (this.addMemberId && memberIndex < 0)
            this.members.push(memberToRefresh);
          if (this.refreshMemberId && memberIndex >= 0)
            this.members[memberIndex] = memberToRefresh;
        }
        this.isResetPasswordUnavailable =
          claims === undefined ||
          claims.authenticationType === AuthenticationType.M365Auth;
        this.isSendInviteUnavailable = this.isResetPasswordUnavailable;
        this.partnerClaims = claims;

        const isCurrentUserConnectWiseBackup =
          this.isCurrentUserConnectWiseBackup();

        if (!isCurrentUserConnectWiseBackup) {
          const accessFilterArea: FilterArea = {
            displayName: 'settings.members.table.access',
            key: this.accessFilterKey,
            selectedFiltersCount: 0,
            filters: [],
          };
          accessFilterArea.filters.push(
            ...MembersAccessProvider.AccessRights.filter(
              (access) =>
                this.isSecurityManagerLicensingFeatureActive() ||
                access.value != 'SecurityManager'
            ).map(
              (access) => new FilterItem(access.displayNameLocKey, access.value)
            )
          );

          this.filterAreas.unshift(accessFilterArea);
        }

        const rolesFilterArea: FilterArea = {
          displayName: 'settings.members.table.roles',
          key: this.accountRolesFilterKey,
          selectedFiltersCount: 0,
          filters: [],
        };
        const roles = isCurrentUserConnectWiseBackup
          ? MembersRoleProvider.ConnectWiseBackupRoles
          : MembersRoleProvider.PartnerPortalRoles;
        rolesFilterArea.filters.push(
          ...roles.map(
            (role) => new FilterItem(role.displayNameLocKey, role.key)
          )
        );

        this.filterAreas.unshift(rolesFilterArea);

        this.initialLoading = false;
        this.isResetMfaAvailable =
          claims !== undefined &&
          claims.isMFAEnabled &&
          this.isCurrentUserAdmin();
      },
      error: () => {
        this.initialLoading = false;
      },
    });

    this.fetchSettings().subscribe();

    this.taskManagerService.configureTask(
      TaskType.Empty,
      null,
      EmptyPanelComponent
    );
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.filterClicksObservable().pipe(startWith('')),
      this.searchInputObservable().pipe(startWith('')),
    ])
      .pipe(
        skip(1),
        debounceTime(500),
        tap(() => (this.loading = true)),
        switchMap(([_, searchTerm]) => this.getMembers(searchTerm, 1))
      )
      .subscribe((members) => {
        this.loading = false;
        this.members = members;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadMoreMembers(): void {
    this.loadingMore = true;
    this.getMembers(
      this.searchInputValue,
      this.currentPageNumber + 1
    ).subscribe((members) => {
      this.loadingMore = false;
      this.appendMembers(members, []);
    });
  }

  resetPassword(member: Member): void {
    this.membersActionEffectHandler
      .resetPassword(this.membersService.resetPassword(member.username), member)
      .subscribe();
  }

  resetMfa(member: Member): void {
    const modalRef = this.modalService.open<ResetMfaModalComponent, any>(
      ResetMfaModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.memberEmail = member.email;
    modalRef.result;
  }

  editPermissions(member: Member): void {
    this.router.navigate(['settings', 'users', 'members', member.id, 'edit']);
  }

  sendInvitation(member: Member): void {
    this.membersService
      .sendInvitation(member.id)
      .pipe(
        switchMap((result) => {
          if (result) {
            return this.translateService
              .get('settings.members.actions.resend-invite-success')
              .pipe(
                tap((successText: string) =>
                  this.toastrService.success(successText)
                )
              );
          }
        }),
        catchError((response: HttpErrorResponse) =>
          this.errorFactory
            .getMessage(response?.error)
            .pipe(
              tap((errorText: string) => this.toastrService.error(errorText))
            )
        )
      )
      .subscribe();
  }

  revokeInvitation(member: Member): void {
    this.membersService
      .revokeInvitation(member.id)
      .pipe(
        switchMap((result) =>
          result
            ? this.getMembers(this.searchInputValue, this.currentPageNumber)
            : of(this.members)
        ),
        switchMap((members) => {
          this.appendMembers(members, [member]);
          return this.translateService
            .get('settings.members.actions.revoke-invite-success')
            .pipe(
              tap((successText: string) =>
                this.toastrService.success(successText)
              )
            );
        }),
        catchError((response: HttpErrorResponse) =>
          this.errorFactory
            .getMessage(response?.error)
            .pipe(
              tap((errorText: string) => this.toastrService.error(errorText))
            )
        )
      )
      .subscribe();
  }

  deactivateAccount(member: Member): void {
    this.membersActionEffectHandler
      .deactivate(this.membersService.deactivateMember(member.id), member)
      .subscribe();
  }

  activateAccount(member: Member): void {
    this.membersService
      .activateMember(member.id)
      .pipe(
        tap((result) => {
          if (result) {
            member.status = MembersStatusProvider.getStatusValueByKey(
              MemberStatus.Active
            );
            this.translateService
              .get('settings.members.actions.activate-success')
              .pipe(
                tap((successText: string) =>
                  this.toastrService.success(successText)
                )
              )
              .subscribe();
          }
        }),
        catchError((response: HttpErrorResponse) =>
          this.errorFactory
            .getMessage(response.error)
            .pipe(
              tap((errorText: string) => this.toastrService.error(errorText))
            )
        )
      )
      .subscribe();
  }

  onAllUsersMatchedChange(areAllMatched: boolean) {
    this.areAllUsersMatched = areAllMatched;
  }

  showDivider(filter: FilterItem): boolean {
    return filter instanceof DividerFilterItem;
  }

  showFilterArea(filerArea: FilterArea): boolean {
    // Currently, this error only occurs if the authentication type is M365.
    // If you need to remove this condition, ensure that backend (auth-n provider) has claim validation.
    if (filerArea.key === this.errorFilterKey) return !this.areAllUsersMatched;

    return true;
  }

  checkFilter(filterArea: FilterArea, filter: FilterItem): void {
    filter.isSelected = !filter.isSelected;
    this.filter([filterArea]);
  }

  showClearFiltersButton(): boolean {
    const totalSelectedFilters = this.filterAreas
      .map((x) => x.selectedFiltersCount)
      .reduce((partialSum, a) => partialSum + a, 0);
    return totalSelectedFilters > 0;
  }

  clearAllFilters(): void {
    this.filterAreas.forEach((area) => {
      area.filters.forEach((filter) => {
        filter.isSelected = false;
      });
    });
    this.filter(this.filterAreas);
  }

  selectMember(member: Member, event: Event): void {
    // Checking if action menu button was clicked in the table row
    const actionMenu = (event.target as Element).closest('sk-actions-menu');
    if (actionMenu) {
      return;
    }

    // Open details pane if table row was clicked (not action menu button)
    const viewModel = {
      id: member.id,
      fullName: member.fullName,
      email: member.email,
      role: member.role,
      access: member.access,
      status: member.status,
      isUsernameMapped: member.isUsernameMapped,
      permissionScopes: member.permissionScopes,
      partnerClaims: this.partnerClaims,
      isMfaEnabled: this.isResetMfaAvailable,
    };

    this.usersComponentContext.infoButtonTaskType = TaskType.MemberDetails;

    this.taskManagerService.configureTask(
      TaskType.MemberDetails,
      new MemberDetailsPanelContext(viewModel, this.areAllUsersMatched),
      MemberDetailsPanelComponent
    );

    this.taskManagerService.activateTask(TaskType.MemberDetails).catch((e) => {
      OperationCancelledError.suppressOnCancel(e);
    });
  }

  getDisplayNameLocKeyForMemberRole(memberRoleKey: string): string {
    return MembersRoleProvider.getRoleDisplayNameLocKeyByKey(
      memberRoleKey,
      false
    );
  }

  formatMemberAccess(access: string[]): string {
    const accessTranslations = [];

    access.sort(
      (a, b) =>
        this.accessSortingList.indexOf(a) - this.accessSortingList.indexOf(b)
    );

    const accessDisplayLocKeys = access.map((x) =>
      MembersAccessProvider.getAccessDisplayNameLocKeyByValue(x)
    );

    if (!accessDisplayLocKeys.length) {
      return '';
    }

    this.translateService
      .get(accessDisplayLocKeys)
      .subscribe((translations) => {
        accessDisplayLocKeys.forEach((value) => {
          accessTranslations.push(translations[value]);
        });
      });

    return accessTranslations.join(', ');
  }

  getDisplayNameLocKeyForMemberStatus(memberStatusKey: string): string {
    return MembersStatusProvider.getStatusDisplayNameLocKeyByValue(
      memberStatusKey
    );
  }

  isOwnMember(member: Member): boolean {
    return (
      this.abstractUserProvider.getCurrentUser().email.toLowerCase() ===
      member.email.toLowerCase()
    );
  }

  isCurrentUserAdmin(): boolean {
    return (
      this.partnerClaims &&
      MembersRoleProvider.isAdmin(this.partnerClaims.legacyAccessRole)
    );
  }

  isCurrentUserConnectWiseBackup(): boolean {
    return (
      this.partnerClaims &&
      MembersRoleProvider.ConnectWiseBackupRoles.some(r => r.key === this.partnerClaims.legacyAccessRole)
    );
  }

  isMemberErrored(member: Member): boolean {
    return (
      this.memberValidator.getMemberErrors(member, this.partnerClaims).length >
      0
    );
  }

  removeAccount(member: Member): void {
    const modalRef = this.modalService.open<RemoveMemberModalComponent, any>(
      RemoveMemberModalComponent,
      { backdrop: 'static' }
    );
    modalRef.componentInstance.memberId = member.id;
    modalRef.result.then((result) => {
      if (result.data) {
        this.getMembers(this.searchInputValue, this.currentPageNumber)
          .pipe(tap((members) => this.appendMembers(members, [member])))
          .subscribe();
      }
    });
  }

  onReauthenticationRequiredChanges(isRequired: boolean) {
    this.isReauthenticateRequired = isRequired;
  }

  reloadMembers() {
    this.loading = true;
    this.getMembers('', 1).subscribe((members) => {
      this.appendMembers([], this.members);
      this.appendMembers(members, []);

      this.taskManagerService.closeTask(TaskType.MemberDetails).catch((e) => {
        OperationCancelledError.suppressOnCancel(e);
      });

      this.loading = false;
    });
  }

  get searchInputValue(): string {
    return this.input.nativeElement.value;
  }

  set searchInputValue(value: string) {
    this.input.nativeElement.value = value;
  }

  private fetchSettings(): Observable<any> {
    this.isLoadingSettings = true;

    const fetchSettings$ = this.authSettingsService
      .fetchAuthenticationSettings()
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([
            partnerAuthentication,
            groupSyncStatus,
            m365AuthConnectionStatus,
          ]) => {
            this.partnerAuthentication = partnerAuthentication;
            this.groupsSyncEnabled = groupSyncStatus.groupsSyncEnabled;
            this.m365AuthConnectionStatus = m365AuthConnectionStatus;
          }
        ),
        finalize(() => (this.isLoadingSettings = false))
      );

    return iif(
      () => this.authService.isCurrentUserAdmin(),
      fetchSettings$,
      of(null).pipe(
        tap(() => {
          this.isLoadingSettings = false;
        })
      )
    );
  }

  private filter(filterAreas: FilterArea[]): void {
    this.updateSelectedFiltersCount(filterAreas);
    this.filterSubject.next(this.getUniqueFilterValue());
  }

  private updateSelectedFiltersCount(filterAreas: FilterArea[]): void {
    filterAreas.forEach((area) => {
      area.selectedFiltersCount = area.filters.filter(
        (filter) => filter.isSelected
      ).length;
    });
  }

  private filterClicksObservable(): Observable<string> {
    return this.filter$.pipe(distinctUntilChanged());
  }

  private searchInputObservable(): Observable<string> {
    return merge(
      fromEvent<any>(this.input.nativeElement, 'keyup').pipe(
        filter((event) => event.code === 'Enter'),
        map((event) => event.target.value)
      ),
      fromEvent<any>(this.searchMembersBtn.nativeElement, 'click').pipe(
        map(() => this.searchInputValue)
      ),
      fromEvent<any>(this.clearSearchBtn.nativeElement, 'click').pipe(
        map(() => {
          this.searchInputValue = '';
          return '';
        })
      )
    ).pipe(distinctUntilChanged());
  }

  private getMembers(
    searchString: string,
    pageNumberToGet: number
  ): Observable<Member[]> {
    const searchFilter = new MembersSearchFilter({
      searchTerm: searchString,
      pageNumber: pageNumberToGet,
      roles: this.getFilterArrayByAreaKey(this.accountRolesFilterKey),
      access: this.getFilterArrayByAreaKey(this.accessFilterKey),
      status: this.getFilterArrayByAreaKey(this.statusFilterKey),
      error: this.getFilterArrayByAreaKey(this.errorFilterKey),
      orderBy: 'AccountRole desc,Name,EmailAddress',
    });
    return this.membersService.getMembers(searchFilter).pipe(
      tap(() => (this.currentPageNumber = pageNumberToGet)),
      tap(
        (members) =>
          (this.showLoadMore = members.length === searchFilter.pageSize)
      ),
      takeUntil(this.destroy$),
      catchError((error) => {
        this.handleLoadingError();
        return throwError(() => error);
      })
    );
  }

  private handleLoadingError(): void {
    this.members = [];
    this.loadMembersError = true;
    this.errorModalService.openErrorModal();
  }

  private getFilterArrayByAreaKey(filterAreaKey: string): string[] {
    const filterArea: FilterArea = this.filterAreas.find(
      (x) => x.key === filterAreaKey
    );

    return filterArea?.filters
      .filter((filterItem) => filterItem.isSelected)
      .map((filterItem) => filterItem.value);
  }

  private getUniqueFilterValue(): string {
    return this.filterAreas
      .map((filterArea) => {
        const filtersString = filterArea.filters
          .filter((x) => x.isSelected)
          .map((x) => x.value)
          .join();
        return `${filterArea.key}[${filtersString}]`;
      })
      .join();
  }

  private appendMembers(membersToAdd: Member[], membersToExclude: Member[]) {
    this.members = this.members.filter((member) =>
      membersToExclude.every(
        (memberToExclude) => member.id !== memberToExclude.id
      )
    );
    this.members.push(
      ...membersToAdd.filter(
        (member) =>
          this.members.every((memberToAdd) => member.id !== memberToAdd.id) &&
          membersToExclude.every(
            (memberToExclude) => member.id !== memberToExclude.id
          )
      )
    );
  }

  private isSecurityManagerLicensingFeatureActive(): boolean {
    return environment.securityManagerLicensingFeatureActive;
  }
}
