import {
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable, of,
} from 'rxjs';
import {
  catchError,
} from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { environment } from "../../../environments/environment";
import { Utils } from "../../services/utils";
import { PartnerSuspensionDetails } from '../models/PartnerSuspensionDetails';

@Injectable({
    providedIn: 'root'
})
export class BillingService {

  constructor(
    private http: HttpClient,
    private abstractUserProvider: AbstractUserProvider,
    private routeResolverService: RouteResolverService
  ) {
  }

  getBillingPortalUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.BillingPortal);
  }

  getPartnerSuspensionStatus(): Observable<PartnerSuspensionDetails> {
    return this.http
      .get<PartnerSuspensionDetails>(
        `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${
          environment.apis.accountingApimSuffix
        }/api/v1/invoice/pastdue/${
          this.abstractUserProvider.getCurrentUser().partnerId
        }/details`
      )
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            const defaultDetails: PartnerSuspensionDetails = {
              isAnyOverdueInvoice: false,
              backupSuspensionStatus: null,
            };
            return of(defaultDetails);
          }

          return Utils.handleError(error, BillingService.name);
        })
      );
  }
}
